.hoox-newsletter {
    padding: 0 0 48px;
}

.hoox-newsletter__form-wrapper {
    max-width: 325px;
    background-color: var(--hoox-color-lilac);
    padding: 36px 25px;
}

.hoox-newsletter__header {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    margin-bottom: 28px;
    align-items: center;
}

.hoox-newsletter__input-email {
    background-color: var(--hoox-color-white);
    width: 100%;
    border: 1px solid var(--hoox-color-slate-dark);
    height: 49px;
    margin-bottom: 8px;
    padding-left: 16px;
    color: var(--hoox-color-slate-dark);
}

.hoox-newsletter__submit {
}

.hoox-newsletter__msg-error {
    display: none;
}
.hoox-newsletter__msg-success {
    display: none;
}
.hoox-newsletter .hoox-newsletter__input-email::placeholder {
    font-size: 18px;
}

.hoox-newsletter__subheading {
    font-size: 18px;
}

.hoox-newsletter form {
}
.hoox-newsletter__form-group-btn {
    background: var(--slate-dark, #4A5761);
    color: white;
    display: flex;
    align-items: c;
    justify-content: center;
    align-items: center;
    height: 47px;
}

.hoox-newsletter__form-group-btn span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.hoox-newsletter__input {
}
.hoox-newsletter__title-success {
    display: none;
}
/* success */ 

.hoox-newsletter__form-wrapper.success .hoox-newsletter__form-group,
.hoox-newsletter__form-wrapper.success .hoox-newsletter__submit-text,
.hoox-newsletter__form-wrapper.success .hoox-newsletter__subheading,
.hoox-newsletter__form-wrapper.success .hoox-newsletter__title .hoox-newsletter__title-default {
    display: none;
}
.hoox-newsletter__form-wrapper.success .hoox-newsletter__title .hoox-newsletter__title-success {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hoox-newsletter__form-wrapper.success .hoox-newsletter__msg-success {
    display: flex;
}

@media (min-width: 1023px) {
    .hoox-newsletter {
        padding-bottom: 80px;
    }
    .hoox-newsletter__form-wrapper {
        max-width: 1200px;
        display: flex;
        gap: 80px;
        padding: 38px 59px;
        align-items: center;
    }

    .hoox-newsletter__header {
        max-width: 442px;
        row-gap: 10px;
        margin-bottom: 0;
        align-items: flex-start;
        text-align: left;
    }

    .hoox-newsletter__form-group {
        max-width: 560px;
        display: flex;
        gap: 20px;
    }

    .hoox-newsletter__input-email {
        width: 360px;
        height: 47px;
        margin-bottom: 0;
    }

    .hoox-newsletter__form-group-btn {
        width: 180px;
    }

    .hoox-newsletter__title-default {
        font-size: 28px;
    }

    .hoox-newsletter__subheading {
        text-align: left;
    }
}

.hoox-newsletter__form-wrapper.success .hoox-newsletter__form-group,
.hoox-newsletter__form-wrapper.success .hoox-newsletter__submit-text,
.hoox-newsletter__form-wrapper.success .hoox-newsletter__subheading,
.hoox-newsletter__form-wrapper.success .hoox-newsletter__title .hoox-newsletter__title-default {
    display: none;
}
.hoox-newsletter__form-wrapper.success .hoox-newsletter__title .hoox-newsletter__title-success {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hoox-newsletter__form-wrapper.success .hoox-newsletter__msg-success {
    display: flex;
}

.hoox-newsletter__form-wrapper.success {
    justify-content: center;
}

/* error */ 
.hoox-newsletter__form-wrapper.error .hoox-newsletter__msg-error, 
.hoox-newsletter__form-wrapper.error .hoox-newsletter__title .hoox-newsletter__title-default {
    display: block;
}
.hoox-newsletter__form-wrapper.error .hoox-newsletter__title .hoox-newsletter__title-success {
    display: none;
}
.hoox-newsletter__form-wrapper.submitted .hoox-newsletter__form-group {
    display: none;
}
.hoox-newsletter__form-wrapper.submitted .hoox-newsletter__title-default {
    display: none;
}
.hoox-newsletter__form-wrapper.submitted .hoox-newsletter__title-success {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hoox-newsletter__form-wrapper.submitted .hoox-newsletter__subheading {
    display: none;
}
.hoox-newsletter__form-wrapper.submitted p.hoox-newsletter__msg-success {
    display: block;
}

/* POPUP */
.hoox-popup {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: none;
    align-items: center;
    justify-content: center;
}

.hoox-popup__wrapper {
    max-width: 335px;
    width: 100%;
    position: relative;
    background: var(--hoox-color-lilac);;
}

.hoox-popup__outside {
    position: fixed;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    left: 0;
    z-index: -1;
    display: block !important;
}


.hoox-popup  .hoox-newsletter__form-wrapper{
    padding: 0;
    flex-direction: column;
    gap: 24px;
    padding: 20px;
}
.hoox-popup .hoox-newsletter__form {
    flex-direction: column;
    position: relative;
    width: 100%;
}

.hoox-popup__close {
    position: absolute;
    top: -15px;
    right: -16px;
    cursor: pointer;
}

.hoox-popup .hoox-newsletter__title {
    text-align: center;
    font-size: 24px;
    line-height: 130%;
}
.hoox-popup .hoox-newsletter__input-email {
    background-color: var(--hoox-color-white);
}

.hoox-popup .hoox-newsletter__form-group {
    flex-direction: column;
    gap: 8px;
}

.hoox-popup .hoox-newsletter__header {
    align-items: center;
}

.hoox-popup .hoox-newsletter__subheading {
    text-align: center;
}

.hoox-popup .hoox-newsletter__input-email {
    width: 100%;
}
.hoox-popup .hoox-newsletter__form-group-btn {
    width: 100%;
}
@media (min-width: 1024px) {
    .hoox-popup__wrapper {
        max-width: 400px;
    }

    .hoox-popup .hoox-newsletter__title {
        font-size: 36px;
        margin-bottom: 8px;
    }
   
}

@media (min-width: 768px) and (max-width: 1023px) {
    .hoox-newsletter__form-wrapper {
        max-width: 100%;
    }
} 

.hoox-newsletter-lp-3 {
    background-color: var(--hoox-color-sand);
    padding-top: 36px;
    padding-bottom: 40px;
}

.hoox-newsletter-lp-3 .hoox-newsletter__form-wrapper {
    background-color: var(--hoox-color-sand);
    padding: 0;
}

@media (min-width: 768px) {
    .hoox-newsletter-lp-3 {
        padding: 80px;
    }
    .hoox-newsletter-lp-3 .hoox-newsletter__form-wrapper {
        padding: 0;
        max-width: 1140px;
        margin-inline: auto;
        gap: 90px;
    }
    .hoox-newsletter-lp-3 .hoox-newsletter__form-group-btn {
        min-width: 225px;
    }
}